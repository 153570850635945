/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import { ThemeProvider } from "emotion-theming"
import React, { ReactNode, useState } from "react"

import theme from "@ui/theme"
import GlobalStyles from "@ui/GlobalStyles"
import styled from "@emotion/styled"
import shouldForwardProp from "@styled-system/should-forward-prop"

import UndpLogo from "../images/undp_logo.inline.svg"
import EULogo from "../images/eu_logo.inline.svg"
import footerPrint from "../images/footer_print.png"
import Nav from "./nav"
import { AnimatePresence } from "framer-motion"
import { Link } from "gatsby"
import { useInView } from "react-intersection-observer"
import ScrollController from "./scroll-controller"
import {
  SpaceProps,
  space,
  layout,
  LayoutProps,
  typography,
  TypographyProps,
} from "styled-system"
import { Text } from "@ui/Typography"

const Header = styled.header`
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  min-height: 1px;
  width: 100vw;
  color: #fff;

  position: fixed;
`

/* prettier-ignore */
const Logo = styled(Link)`
  top:0;
  left: 0;
  position: absolute;
  display: flex;
  margin: 2rem;
  margin-right: auto;
  color: currentColor;
  text-decoration: none;

  &:focus {
    outline: 0;
  }

  transition: opacity 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 1;

  ${Header}[data-stuck="true"][data-nav-open="false"] & {
    pointer-events: none;
    opacity: 0;
  }
`

const LogoSymbol = styled(UndpLogo, { shouldForwardProp })<
  SpaceProps & LayoutProps
>`
  color: #155ead;
  width: 100%;
  max-width: 80px;
  ${space};
  ${layout};
`

const LogoSymbols = styled.div`
  display: flex;
  /* flex-direction: column; */
  justify-content: flex-start;
  align-items: flex-start;
`

const EULogoSymbol = styled(EULogo, { shouldForwardProp })<
  SpaceProps & LayoutProps
>`
  width: 100%;
  max-width: 106px;
  ${space};
  ${layout};
`

/* prettier-ignore */
const EULogoHeader = styled(EULogoSymbol)`
  position: absolute;
  right: 7rem;
  top: 2rem;

  transition: opacity 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 1;

  display: none;

  ${Header}[data-stuck="true"][data-nav-open="false"] & {
    pointer-events: none;
    opacity: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: block;
  }
`

const LogoText = styled.div<TypographyProps & SpaceProps>`
  color: currentColor;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  ${typography};

  span {
    font-style: italic;
  }

  ${space};
`

// prettier-ignore
const NavToggle = styled.button`
  position: absolute;
  top: 0;
  right: 0;

  background: transparent;
  appearance: none;
  border: 0;
  margin: 1rem;
  margin-right: 2rem;
  padding: 1rem;
  cursor: pointer;
  color: currentColor;

  transition: all 200ms cubic-bezier(0.645, 0.045, 0.355, 1);

  &:focus {
    outline: 0;
  }

  ${Header}[data-stuck="true"][data-nav-open="false"] & {
    background-color: rgba(80, 80, 80, 0.25);
  }

  @media (hover: none) and (pointer: coarse) {
    margin-right: 1rem;
  }
`

const NavToggleIcon = styled.div`
  position: relative;
  width: 22px;
  height: 22px;
  transform: rotate(0deg);
  transition: transform 100ms cubic-bezier(0.645, 0.045, 0.355, 1);

  &:before {
    content: "";
    position: absolute;
    top: 7px;
    right: 0;
    height: 2px;
    width: 100%;
    background-color: currentColor;
    transition: width 50ms cubic-bezier(0.645, 0.045, 0.355, 1),
      transform 100ms cubic-bezier(0.645, 0.045, 0.355, 1),
      top 100ms 120ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &:not([data-close="true"]):hover:before {
    width: 70%;
  }

  &:after {
    content: "";
    position: absolute;
    top: 12px;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: currentColor;
    transition: transform 100ms cubic-bezier(0.645, 0.045, 0.355, 1),
      top 100ms 120ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &[data-close="true"] {
    transform: rotate(45deg);
    transition: transform 100ms 120ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &[data-close="true"]:before {
    content: "";
    position: absolute;
    transform: rotate(-90deg);
    top: 9px;
    right: 0;
    height: 2px;
    width: 100%;
    background-color: currentColor;
    transition: width 50ms cubic-bezier(0.645, 0.045, 0.355, 1),
      top 100ms cubic-bezier(0.645, 0.045, 0.355, 1),
      transform 100ms 120ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &[data-close="true"]:after {
    content: "";
    position: absolute;
    top: 9px;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: currentColor;
    transition: top 100ms cubic-bezier(0.645, 0.045, 0.355, 1),
      top 100ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`

const Footer = styled.footer`
  position: relative;
  background-color: ${({ theme }) => theme.colors.cyprus};
  background-image: url(${footerPrint});
  background-size: cover;
  background-position: center;
  color: ${({ theme }) => theme.colors.wheat};
  width: 100%;
`

const FooterLogo = styled.div`
  margin-top: ${({ theme }) => theme.space["3xl"]};
  display: flex;
  align-items: flex-start;

  @media screen and (min-width: 55rem) {
    margin-top: 0;
    flex-flow: row-reverse;
  }
`

const FooterLayout = styled.div`
  max-width: 73rem;
  margin: auto;

  padding: ${({ theme }) => `${theme.space["5xl"]} ${theme.space["xl"]}`};
`

const FooterTwoColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: 55rem) {
    flex-direction: row;
  }
`

const TwitterTag = styled.span`
  font-weight: bold;
`

const FooterSummary = styled.div`
  display: block;
  margin: 0;
  max-width: 34rem;
`

const FooterMailto = styled.a`
  display: block;
  color: currentColor;
`

const StickySentinel = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 20vh;
  bottom: 0;
  visibility: hidden;
`

const Copy = styled.div`
  margin: ${({ theme }) => theme.space["md"]};
`

const FooterLink = styled.a`
  color: inherit;
  text-decoration: none;
  display: inline-block;
  vertical-align: top;

  opacity: 0.7;

  transition: opacity 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
  margin: ${({ theme }) => theme.space["md"]};

  &:hover,
  &:focus {
    opacity: 1;
    text-decoration: underline;
  }
`

const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (min-width: 55rem) {
    flex-direction: row;
  }
`

const CopyrightBar = styled.div`
  background-color: #111;
  color: #fff;
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.fonts.secondary};

  flex-direction: column;

  @media screen and (min-width: 80rem) {
    flex-direction: row;
  }
`

const ChangeOrientationMessage = styled.div`
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.cyprus};
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  display: none;

  @media screen and (orientation: landscape) and (max-width: 52rem) {
    display: flex;
  }
`

interface Props {
  children: ReactNode
  color?: string
  showFooter?: boolean
  headerRef?: any
}

const Layout = ({ children, showFooter = false, headerRef }: Props) => {
  const [navRef, isStuck] = useInView({
    rootMargin: "-2% 0px -95% 0px",
  })

  const [navOpen, setNavOpen] = useState<boolean>(false)

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <AnimatePresence>
        {navOpen && <Nav onClose={() => setNavOpen(false)} />}
      </AnimatePresence>
      <Header ref={headerRef} data-stuck={isStuck} data-nav-open={navOpen}>
        <Logo tabIndex={-1} to="/">
          <LogoSymbols>
            <LogoSymbol maxWidth={{ _: "3rem", lg: "5rem" }} />
          </LogoSymbols>
          <LogoText
            ml={{ _: "sm", lg: "lg" }}
            fontSize={["sm", "md"]}
            data-wf-wait
          >
            Entry and Exit Points: <br />
            Violent Extremism <br />
            <span>in South-East Asia</span>
          </LogoText>
        </Logo>

        <EULogoHeader maxWidth={{ _: "4.5rem", lg: "6.5rem" }} />
        <NavToggle onClick={() => setNavOpen(prev => !prev)}>
          <NavToggleIcon data-close={navOpen} />
        </NavToggle>
      </Header>
      <StickySentinel ref={navRef} />
      <ScrollController>
        <main>{children}</main>
      </ScrollController>
      {showFooter && (
        <Footer>
          <FooterLayout>
            <FooterTwoColumn>
              <FooterSummary>
                <Text mt="0">
                  Investigating factors that contribute towards violent
                  extremism in South-East Asia, and the actions required by
                  states to prevent it.
                </Text>
                <Text>
                  ≫ Follow the conversation on social media:{" "}
                  <TwitterTag>#EntryandExitPoints</TwitterTag>
                </Text>
                <Text fontSize="lg" variant="effect" as="h3" mb="0">
                  Email
                </Text>
                <FooterMailto href="mailto:pveteambrh@undp.org">
                  pveteambrh@undp.org
                </FooterMailto>
              </FooterSummary>
              <div>
                <FooterLogo>
                  <LogoSymbol maxWidth={["3rem", "5rem"]} />
                  <EULogoSymbol
                    maxWidth={["4rem", "6.5rem"]}
                    mx={["md", "lg"]}
                  />
                </FooterLogo>
              </div>
            </FooterTwoColumn>
            <Text
              mb="0"
              mt="5xl"
              lineHeight="base"
              fontStyle="italic"
              fontSize="xs"
            >
              This website was produced with the financial assistance of the
              European Union’s Service for Foreign Policy Instruments. The views
              expressed in this website are those of the author(s) and do not
              necessarily represent those of the United Nations, including UNDP,
              or the UN Member States; and can in no way be taken to reflect the
              official opinion of the European Union.
            </Text>
            <Text
              mb="0"
              mt="md"
              lineHeight="base"
              fontSize="xs"
              fontStyle="italic"
            >
              The designations employed and the presentation of material on this
              map do not imply the expression of any opinion whatsoever on the
              part of the Secretariat of the United Nations or UNDP concerning
              the legal status of any country, territory, city or area or its
              authorities, or concerning the delimitation of its frontiers or
              boundaries.
            </Text>
          </FooterLayout>
          <CopyrightBar>
            <Copy>&copy; 2020 United Nations Development Programme</Copy>
            <FooterLinks>
              <FooterLink href="https://www.undp.org/content/undp/en/home/accountability/audit/office-of-audit-and-investigation.html">
                Report, fraud, abuse, misconduct
              </FooterLink>
              <FooterLink href="https://www.undp.org/content/undp/en/home/accountability/audit/secu-srm.html">
                Submit social or environmental complaint
              </FooterLink>
              <FooterLink href="https://www.undp.org/content/undp/en/home/scam-alert.html">
                Scam alert
              </FooterLink>
              <FooterLink href="https://www.undp.org/content/undp/en/home/copyright-and-termsofuse.html">
                Terms of use
              </FooterLink>
            </FooterLinks>
          </CopyrightBar>
        </Footer>
      )}
      <ChangeOrientationMessage>
        <Text variant="effect" color="wheat">
          Please rotate your device!
        </Text>
      </ChangeOrientationMessage>
    </ThemeProvider>
  )
}

export default Layout
