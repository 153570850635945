import React, { useRef, useEffect, useState } from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock"
import FocusLock from "react-focus-lock"
import { motion, Variants, AnimatePresence } from "framer-motion"
import { TypographyProps, typography } from "styled-system"
import { css, Theme } from "@emotion/react"

const Background = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.cyprus};
  height: 100%;
  width: 100%;
  padding-top: 10rem;
  position: fixed;
  z-index: 50;
  display: flex;
`

const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`

const NavLinks = styled.nav`
  margin: 0 auto 4rem;

  @media screen and (min-width: 70rem) {
    margin: 0 auto 4rem;
  }
`

const navLinkCss = ({ theme }: { theme: Theme }) => css`
  display: block;
  padding: ${theme.space["2xs"]} 0;
  text-decoration: none;
  text-transform: uppercase;
  font-family: ${theme.fonts.secondary};
  line-height: ${theme.lineHeights.base};
  font-weight: 600;
  color: ${theme.colors.wheat};
  transition: color 150ms cubic-bezier(0.645, 0.045, 0.355, 1);

  &:hover,
  &:focus {
    color: #fff;
    outline: 0;
  }
`

const NavLink = styled(Link)<TypographyProps>`
  ${navLinkCss}
  ${typography};
`

NavLink.defaultProps = {
  fontSize: ["2xl", "4xl", "5xl"],
}

const SubmenuHeading = styled.button<TypographyProps>`
  ${navLinkCss}
  ${typography};
  border: 0;
  background: transparent;
  appearance: none;
  cursor: pointer;
`

SubmenuHeading.defaultProps = {
  fontSize: ["2xl", "4xl", "5xl"],
}

const backgroundAnimation: Variants = {
  initial: {
    transform: "translateX(100%)",
  },
  closed: {
    transform: "translateX(-100%)",
    transition: {
      when: "afterChildren",
      duration: 0.35,
    },
  },
  open: {
    transform: "translateX(0%)",
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.05,
      duration: 0.45,
    },
  },
}

const linkAnimation: Variants = {
  initial: {
    translateY: "-10%",
    opacity: 0,
  },
  closed: {
    opacity: 0,
    transition: {
      duration: 0.15,
    },
  },
  open: {
    opacity: 1,
    translateY: "0%",
  },
}

type NavProps = {
  onClose: () => void
}

const Nav = ({ onClose }: NavProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const [isSubmenuVisible, setSubmenuVisible] = useState<boolean>(false)

  useEffect(() => {
    if (ref.current) {
      disableBodyScroll(ref.current, { reserveScrollBarGap: true })
    }
    return () => {
      clearAllBodyScrollLocks()
    }
  }, [])

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Escape") {
      onClose()
    }
  }

  return (
    <FocusLock returnFocus autoFocus={false}>
      <Background
        ref={ref}
        variants={backgroundAnimation}
        onKeyDown={onKeyDown}
        initial="initial"
        animate="open"
        exit="closed"
      >
        <ScrollWrapper>
          <NavLinks>
            <NavLink to="/" onClick={onClose}>
              <motion.span initial="initial" variants={linkAnimation}>
                Home
              </motion.span>
            </NavLink>

            <NavLink to="/about" onClick={onClose}>
              <motion.span initial="initial" variants={linkAnimation}>
                About
              </motion.span>
            </NavLink>

            <NavLink to="/findings" onClick={onClose}>
              <motion.span initial="initial" variants={linkAnimation}>
                Main findings
              </motion.span>
            </NavLink>

            <SubmenuHeading
              tabIndex={0}
              onClick={(event: React.SyntheticEvent) => {
                event.preventDefault()
                setSubmenuVisible(prev => !prev)
              }}
            >
              <motion.span initial="initial" variants={linkAnimation}>
                Stories
              </motion.span>
            </SubmenuHeading>

            <AnimatePresence initial={false}>
              {isSubmenuVisible && (
                <motion.div
                  initial="collapsed"
                  animate="open"
                  exit="collapsed"
                  style={{ overflow: "hidden" }}
                  transition={{
                    duration: 0.45,
                    ease: [0.04, 0.62, 0.23, 0.98],
                  }}
                  variants={{
                    open: { opacity: 1, height: "auto" },
                    collapsed: { opacity: 0, height: 0 },
                  }}
                >
                  <NavLink
                    fontSize={["xl", "2xl", "3xl"]}
                    to="/story/odin"
                    onClick={onClose}
                  >
                    <motion.span initial="initial" variants={linkAnimation}>
                      Odin's story
                    </motion.span>
                  </NavLink>

                  <NavLink
                    fontSize={["xl", "2xl", "3xl"]}
                    to="/story/rahma"
                    onClick={onClose}
                  >
                    <motion.span initial="initial" variants={linkAnimation}>
                      Rahma's story
                    </motion.span>
                  </NavLink>

                  <NavLink
                    fontSize={["xl", "2xl", "3xl"]}
                    to="/story/ahmad"
                    onClick={onClose}
                  >
                    <motion.span initial="initial" variants={linkAnimation}>
                      Ahmad's story
                    </motion.span>
                  </NavLink>

                  <NavLink
                    fontSize={["xl", "2xl", "3xl"]}
                    to="/story/amir"
                    onClick={onClose}
                  >
                    <motion.span initial="initial" variants={linkAnimation}>
                      Amir's story
                    </motion.span>
                  </NavLink>

                  <NavLink
                    fontSize={["xl", "2xl", "3xl"]}
                    to="/story/khairul"
                    onClick={onClose}
                  >
                    <motion.span initial="initial" variants={linkAnimation}>
                      Khairul's story
                    </motion.span>
                  </NavLink>
                </motion.div>
              )}
            </AnimatePresence>
            <NavLink to="/about#reports" onClick={onClose}>
              <motion.span initial="initial" variants={linkAnimation}>
                Download reports
              </motion.span>
            </NavLink>
          </NavLinks>
        </ScrollWrapper>
      </Background>
    </FocusLock>
  )
}

export default Nav
