import React, { useEffect, useContext, useState } from "react"

const ScrollContext = React.createContext<any>(null)

interface Props {
  children: React.ReactNode
}

export const useScrollController = () => useContext(ScrollContext)

const ScrollController = ({ children }: Props) => {
  const [state, setState] = useState<any>({})
  useEffect(() => {
    ;(async () => {
      const ScrollMagic: any = await import("scrollmagic")
      setState({
        ScrollMagic,
        controller: new ScrollMagic.Controller(),
      })
    })()

    return () => {
      if (state.controller) {
        state.controller.destroy(true)
      }
    }
    // eslint-disable-next-line
  }, [])

  return (
    <ScrollContext.Provider value={state}>{children}</ScrollContext.Provider>
  )
}

export default ScrollController
