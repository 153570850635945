import { rem } from "polished"
// theme.js

type Breakpoints = string[] & {
  sm: string
  md: string
  lg: string
  xl: string
}

const breakpoints: Breakpoints = ["40em", "52em", "64em", "80em"] as Breakpoints

// aliases
breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

const theme = {
  breakpoints,
  colors: {
    coral: "#FF3D4B",
    wine: "#451B29",
    wheat: "#EFE3B5",
    cyprus: "#01383B",
    sunshade: "#FAB44E",
    midnight: "#000A3A",
    airdust: "#7F9CB2",
    jungle: "#13AEAC",
    viridian: "#498275",
    grey50: "#F7F7F9",
    grey100: "#EEEEF0",
    grey200: "#E2E2E4",
    grey300: "#D0D0D2",
    grey400: "#ABABAD",
    grey500: "#8A8A8C",
    grey600: "#636365",
    grey700: "#505052",
    grey800: "#323234",
    grey900: "#121214",
  },
  sizes: {
    /** 4px */
    "3xs": rem("4px"),
    /** 8px */
    "2xs": rem("8px"),
    /** 12px */
    xs: rem("12px"),
    /** 14px */
    sm: rem("14px"),
    /** 16px */
    md: rem("16px"),
    /** 24px */
    lg: rem("24px"),
    /** 32px */
    xl: rem("32px"),
    /** 40px */
    "2xl": rem("40px"),
    /** 48px */
    "3xl": rem("48px"),
    /** 64px */
    "4xl": rem("64px"),
    /** 96px */
    "5xl": rem("96px"),
  },
  space: {
    /** 4px */
    "3xs": rem("4px"),
    /** 8px */
    "2xs": rem("8px"),
    /** 12px */
    xs: rem("12px"),
    /** 14px */
    sm: rem("14px"),
    /** 16px */
    md: rem("16px"),
    /** 24px */
    lg: rem("24px"),
    /** 32px */
    xl: rem("32px"),
    /** 40px */
    "2xl": rem("40px"),
    /** 48px */
    "3xl": rem("48px"),
    /** 64px */
    "4xl": rem("64px"),
    /** 96px */
    "5xl": rem("96px"),
  },
  fonts: {
    primary:
      'source-serif-pro, -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;',
    secondary:
      'din-2014, -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;',
    effect:
      'din-condensed, -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;',
  },
  fontSizes: {
    /** 10px */
    "2xs": rem("10px"),
    /** 12px */
    xs: rem("12px"),
    /** 14px */
    sm: rem("14px"),
    /** 16px */
    md: rem("16px"),
    /** 18px */
    lg: rem("18px"),
    /** 20px */
    xl: rem("20px"),
    /** 24px */
    "2xl": rem("24px"),
    /** 28px */
    "3xl": rem("28px"),
    /** 36px */
    "4xl": rem("36px"),
    /** 44px */
    "5xl": rem("44px"),
    /** 52px */
    "6xl": rem("52px"),
    /** 60px */
    "7xl": rem("60px"),
  },
  letterSpacings: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
  },
  lineHeights: {
    normal: "normal",
    none: "1",
    shorter: "1.25",
    short: "1.375",
    base: "1.5",
    tall: "1.625",
  },
  fontWeights: {
    normal: 400,
    semi: 600,
    bold: 700,
  },
}

export type Theme = typeof theme

export default theme
