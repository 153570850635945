import Parallax from "@components/parallax"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import GatsbyImage from "gatsby-image"
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from "styled-system"
import { Text } from "@ui/Typography"

export type BlockProps = SpaceProps & LayoutProps & Omit<ColorProps, "color">

export const Block = styled.section<BlockProps>`
  position: relative;
  z-index: 0;
  min-height: 100vh;
  width: 100%;

  ${space}
  ${layout}
  ${color}
`

export const TextBlock = styled(Block)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`

TextBlock.defaultProps = {
  p: "xl",
}

export const Intro = styled(Block)`
  background: #fff;
  color: ${({ theme }) => theme.colors.cyprus};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: auto;
`

TextBlock.defaultProps = {
  p: "xl",
}

export const CenterText = styled(Text)`
  width: 100%;
  max-width: 40rem;
  margin: 4rem 0;
`

CenterText.defaultProps = {
  fontSize: "2xl",
  lineHeight: "tall",
  p: "xl",
}

export const CenterCite = styled.blockquote<
  LayoutProps & Omit<ColorProps, "color">
>`
  font-family: ${({ theme }) => theme.fonts.effect};
  font-size: ${({ theme }) => theme.fontSizes["4xl"]};
  line-height: 1.25;
  text-transform: uppercase;
  text-align: center;

  margin: auto;

  ${layout}
  ${color}

  @media screen and (min-width: 50em) {
    font-size: ${({ theme }) => theme.fontSizes["5xl"]};
  }

  @media screen and (min-width: 70em) {
    font-size: ${({ theme }) => theme.fontSizes["7xl"]};
  }
`

export const StoryHeading = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), transparent);
`

export const StoryHeadingBg = styled.div`
  position: relative;
  width: 100%;
  max-width: 90rem;
  margin: 0 auto;
  background-color: #fff;
  padding: 2.5rem 2rem;
  text-align: center;

  box-shadow: 0 0 128px rgba(0, 0, 0, 0.3);
`

export const StoryTitle = styled(Text)()

StoryTitle.defaultProps = {
  variant: "effect",
  fontSize: "3xl",
  color: "cyprus",
  margin: "0",
  mx: "auto",
}

export const StoryMeta = styled.span`
  font-size: ${({ theme }) => theme.fontSizes["2xl"]};
  display: block;
  margin: 0 auto;
`

export const IntroImage = styled(GatsbyImage)`
  max-width: 80rem;
  width: 100%;
`

export const NavLinks = styled.div`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
`

export const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  color: #fff;
  font-family: ${({ theme }) => theme.fonts.secondary};
  text-transform: uppercase;
  text-decoration: none;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  white-space: nowrap;

  margin: 1rem;
`

export const Figure = styled.figure<SpaceProps & LayoutProps>`
  position: relative;
  width: 100%;
  max-height: 100%;
  max-width: 80rem;
  margin: 0 auto;

  display: flex;
  flex-direction: column;

  ${space}
  ${layout}
`

export const FigCaption = styled.figcaption<Omit<ColorProps, "color">>`
  margin-top: 1rem;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 600;
  text-align: left;
  margin-left: 1rem;

  ${color}

  @media screen and (min-width: 81rem) {
    margin-left: 0;
  }
`

export const ParallaxBlock = styled(Parallax)`
  position: relative;
  z-index: 1;
  min-height: 100vh;
  width: 100%;
`

export const VideoQuote = styled.div`
  position: absolute;

  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 2rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`
