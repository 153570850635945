import styled from "@emotion/styled"

import {
  LayoutProps,
  color,
  ColorProps,
  layout,
  space,
  SpaceProps,
  TypographyProps,
  typography,
  variant,
} from "styled-system"

export const Title = styled.h2<
  TypographyProps & SpaceProps & ColorProps & LayoutProps & { color?: string }
>`
  margin: ${({ theme }) => theme.sizes.xl} 0;
  font-family: ${({ theme }) => theme.fonts.effect};
  font-weight: normal;
  text-transform: uppercase;
  line-height: 1.25;

  ${space};
  ${layout};
  ${typography};
  ${color};
`

Title.defaultProps = {
  fontSize: { _: "3xl", md: "5xl", lg: "6xl" },
}

export const Text = styled.p<
  TypographyProps &
    SpaceProps &
    ColorProps &
    LayoutProps & { color?: string; variant?: any }
>`
  margin: ${({ theme }) => theme.sizes.lg} 0;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes["lg"]};
  font-weight: normal;
  line-height: 1.5;

  ${space};
  ${layout};
  ${typography};
  ${color};

  ${variant({
    variants: {
      effect: {
        textTransform: "uppercase",
        fontFamily: "effect",
      },
    },
  })}
`
